.tomorrow-night-eighties-ansi-theme .ansi-black-fg {
  color: #3a3a3a;
}
.tomorrow-night-eighties-ansi-theme .ansi-red-fg {
  color: #dd948e;
}
.tomorrow-night-eighties-ansi-theme .ansi-green-fg {
  color: #b6d1aa;
}
.tomorrow-night-eighties-ansi-theme .ansi-yellow-fg {
  color: #f3d57c;
}
.tomorrow-night-eighties-ansi-theme .ansi-blue-fg {
  color: #8aa9d5;
}
.tomorrow-night-eighties-ansi-theme .ansi-magenta-fg {
  color: #cbafd5;
}
.tomorrow-night-eighties-ansi-theme .ansi-cyan-fg {
  color: #9ad1d4;
}
.tomorrow-night-eighties-ansi-theme .ansi-white-fg {
  color: #efefef;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-black-fg {
  color: #5e5d5e;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-red-fg {
  color: #e69b94;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-green-fg {
  color: #d1f0c3;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-yellow-fg {
  color: #f4d799;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-blue-fg {
  color: #a6cbfe;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-magenta-fg {
  color: #e7c7f2;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-cyan-fg {
  color: #b0f0f5;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-white-fg {
  color: #fff;
}

.tomorrow-night-eighties-ansi-theme .ansi-black-bg {
  background-color: #3a3a3a;
}
.tomorrow-night-eighties-ansi-theme .ansi-red-bg {
  background-color: #dd948e;
}
.tomorrow-night-eighties-ansi-theme .ansi-green-bg {
  background-color: #b6d1aa;
}
.tomorrow-night-eighties-ansi-theme .ansi-yellow-bg {
  background-color: #f3d57c;
}
.tomorrow-night-eighties-ansi-theme .ansi-blue-bg {
  background-color: #8aa9d5;
}
.tomorrow-night-eighties-ansi-theme .ansi-magenta-bg {
  background-color: #cbafd5;
}
.tomorrow-night-eighties-ansi-theme .ansi-cyan-bg {
  background-color: #9ad1d4;
}
.tomorrow-night-eighties-ansi-theme .ansi-white-bg {
  background-color: #efefef;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-black-bg {
  background-color: #5e5d5e;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-red-bg {
  background-color: #e69b94;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-green-bg {
  background-color: #d1f0c3;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-yellow-bg {
  background-color: #f4d799;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-blue-bg {
  background-color: #a6cbfe;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-magenta-bg {
  background-color: #e7c7f2;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-cyan-bg {
  background-color: #b0f0f5;
}
.tomorrow-night-eighties-ansi-theme .ansi-bright-white-bg {
  background-color: #fff;
}
